/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import './Article.scss';

export const ArticleMiddle = () => {
  return (
    <div className="th-article-content">
      <div className="th-article-middle th-bg-image">
        <div className="th-bg-layer">&nbsp;</div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-11 offset-lg-1">
              <div className="th-article-content__header__light">Our Skills</div>
              <div className="th-article-platform">
                <div className="row no-padding">
                  <div className="col-lg-4 no-padding">
                    <div className="th-article-platform-item">
                      <h4>IoT device HW </h4>
                      <p>
                        HW components: Nordic Semiconductors, Microchip, ST Wireless Technologies: 3G, 4G, Bluetooth,
                        LTEM, NB IoT, LoRa
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 no-padding">
                    <div className="th-article-platform-item">
                      <h4>IoT Devices</h4>
                      <p>sensors, processors, embedded systems, devices IoT products</p>
                    </div>
                  </div>
                  <div className="col-lg-4 no-padding">
                    <div className="th-article-platform-item no-border">
                      <h4>IoT Enablement and Engagement</h4>
                      <p>We develop end-user applications and accompanying backend and cloud solutions.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ArticleBottom = () => {
  return (
    <div className="th-article-content">
      <div className="th-article-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="th-article-content__header th-article-bottom-header">
                Proven experience in designing and developing entire solutions
              </div>
            </div>
            <div className="col-8">
              <div className="th-article-content__paragraph th-article-bottom-paragraph">
                With proven experience in designing and developing entire solutions centered around a physical device,
                and promoting user interactions through our knowledge of what makes users truly engage with a platform,
                we've managed to change the way users perform day-to-day tasks. Our team includes mobile development and
                backend experts, allowing us to build every part of a solution and tailor every part for end-user
                engagement.
              </div>
            </div>
            <div className="col-4 th-article-bottom__svg">
              <img src="/usecase/sq.svg" alt="svg" />
            </div>
            <div className="col-8 th-article-bottom-contact">
              <button type="button">Contact us</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ArticleContent = () => {
  return (
    <div className="th-article-content">
      <div className="th-article-description">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="th-article-content__header th-article-description-header">
                Proven experience in designing and developing entire solutions
              </div>
            </div>
            <div className="col-8">
              <div className="th-article-content__paragraph th-article-description-paragraph">
                With proven experience in designing and developing entire solutions centered around a physical device,
                and promoting user interactions through our knowledge of what makes users truly engage with a platform,
                we've managed to change the way users perform day-to-day tasks. Our team includes mobile development and
                backend experts, allowing us to build every part of a solution and tailor every part for end-user
                engagement.
              </div>
            </div>
            <div className="col-4 th-article-description__image">
              <img src="/usecase/sqblack.png" alt="decor" />
            </div>
            <div className="col-4 th-article-description__image">
              <div className="th-article-content__header th-article-description-header">Challenge</div>
              <ul>
                <li>Transition to 4G that required new GPS trackers hardware and firmware</li>
                <li>Enhancinge new business opportunities by adding new features and functionality</li>
                <li>Scaling up development capabilities to meet customers expectations</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
